html {
  box-sizing: border-box;
}
body {
  margin: 0;
  padding: 0;
  font-family: Lato,'Helvetica Neue',Arial,Helvetica,sans-serif;
  overflow-x: hidden;
  min-width: 320px;
  background: #FFF;
  line-height: 1.4285em;
}
/*

.ReactModal__Body--open {
  overflow: hidden;
}
*/

* {
  box-sizing: inherit;
}
