.popupTitle{
    color: rgb(10, 120, 185);
    font-size: 30px;
    margin-top: 30px;
    margin-bottom: 30px;
    text-align: center;
}

.popupMessage{
    margin-bottom: 30px;
    margin-left: 30px;
    margin-right: 30px;
    color: black;
    font-size: 15px;
}

.popupConfirmButton{
    background-color: rgb(10, 120, 185);
    color: white;
    outline: none;
    border: none;
    width: 150px;
    height: 55px;
    font-size: 15px;
    margin-left:30px;
    margin-bottom: 30px;
}


.popupCancelButton{
    background-color: #C9C9C9;
    color: black;
    outline: none;
    border: none;
    width: 150px;
    height: 55px;
    font-size: 15px;
    float: right;
    margin-right: 30px;
    margin-bottom: 30px;
}
