.App {
  text-align: center;
  font-family: 'Lato', sans-serif;
  /*margin-right: calc(100% - 100vw);*/
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}


.scrollHeader {
  position: absolute;
  display: block;
  opacity: 0;
  -moz-transition: all 700ms ease-out;
  -webkit-transition: all 700ms ease-out;
  -o-transition: all 700ms ease-out;
  transition: all 700ms ease-out;
  -moz-transform: translate3d(0px, -200px, 0px);
  -webkit-transform: translate3d(0px, -200px, 0px);
  -o-transform: translate(0px, -200px);
  -ms-transform: translate(0px, -200px);
  transform: translate3d(0px, 78px, 0px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.scrollHeader.inView {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.scrollHeader.outView {
  opacity: 0;
  -moz-transform: translate3d(0px, -78px, 0px);
  -webkit-transform: translate3d(0px, 200px, 0px);
  -o-transform: translate(0px, 200px);
  -ms-transform: translate(0px, 200px);
  transform: translate3d(0px, -78px, 0px);
}
@keyframes App-logo-spin {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

